import { disableVesselLoadFactors, enableVesselLoadFactors } from '@/api/aqvvesselloadfactor';

const disableVesselLoadFactor = {
	id: 'disableVesselLoadFactor',
	selectionType: 'multiple',
	label: 'aqvvesselloadfactor.actions.disableVesselLoadFactor',
	functionality: 'UPDATE_AQVVESSELLOADFACTOR',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvvesselloadfactor.actions.disableVesselLoadFactor');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvvesselloadfactorid: registries[i].aqvvesselloadfactorid
			});
		}

		const data = await disableVesselLoadFactors(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVesselLoadFactor = {
	id: 'enableVesselLoadFactor',
	selectionType: 'multiple',
	label: 'aqvvesselloadfactor.actions.enableVesselLoadFactor',
	functionality: 'UPDATE_AQVVESSELLOADFACTOR',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvvesselloadfactor.actions.enableVesselLoadFactor');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvvesselloadfactorid: registries[i].aqvvesselloadfactorid
			});
		}

		const data = await enableVesselLoadFactors(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVesselLoadFactor, enableVesselLoadFactor]
};
